<template>
    <div class="payTypelateDetail">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="名称">
                    <el-input v-model="form.template.name" style="width: 200px" readonly="readonly" />
                </el-form-item>
                <el-form-item label="机构">
                    <el-input v-model="form.deptName" style="width: 200px" readonly="readonly" />
                </el-form-item>
                <el-form-item label="可支付(收银)">
                    <el-switch v-model="form.template.canPay" disabled />
                </el-form-item>
                <el-form-item label="可退款(收银)">
                    <el-switch v-model="form.template.canRechargeRefund" disabled />
                </el-form-item>
                <el-form-item label="可抹零(收银)">
                    <el-switch v-model="form.template.canFloor" disabled />
                </el-form-item>
                <el-form-item label="可组合支付(收银)">
                    <el-switch v-model="form.template.canCombinationPay" disabled />
                </el-form-item>
                <el-form-item label="可充值(会员)">
                    <el-switch v-model="form.template.canRecharge" disabled />
                </el-form-item>
                <el-form-item label="可退款(会员)">
                    <el-switch v-model="form.template.canRefund" disabled />
                </el-form-item>
                <el-form-item label="启用">
                    <el-switch v-model="form.enable" disabled />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'PayTypelateDetail',
    props: ['form'],
    data() {
        return {};
    },
    methods: {},
};
</script>
